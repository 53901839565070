export type EventValues =
  | 'TRANSACTION_CREATED'
  | 'INITIAL_RECEIPT_SENT'
  | 'TRACK_INFO_ENQUIRY'
  | 'TRACK_INFO_REQUESTED'
  | 'TRACK_INFO_UPDATED'
  | 'TRACK_RECEIPT_SENT'
  | 'REPORT_ISSUE'
  | 'CASE_CREATED'
  | 'REFUND_UPDATED'
  | 'DELIVERY_SIGNED_UPDATED'
  | 'ARN_UPDATED'
  | 'OI_CASE_CREATED'
  | 'CE_CASE_CREATED'
  | 'CE_CASE_STATUS_UPDATED'
  | 'CLOSED';

export type Event =
  | {
    event: 'CASE_CREATED';
    time: string;
    case_id: string;
    issue: string;
    request: {
      type: string;
      message: string;
      amount: string | null;
      currency: string | null;
      brand: string;
      reason: string;
      filename?: string | null;
    };
  }
  | {
    event: 'REFUND_UPDATED';
    time: string;
    case_id: null;
    refund: string | null;
  }
  | {
    event: Exclude<EventValues, 'CASE_CREATED' | 'REFUND_UPDATED'>;
    time: string;
    case_id: null;
  };

export type CompellingEvidenceEvent =
  | {
    event: 'CASE_CREATED';
    time: string;
    case_id: string;
    issue: string;
    refund: string;
    amount: string | null;
    currency: string | null;
    filename?: string | null;
    message: string;
  }
  | {
    event: 'REFUND_UPDATED';
    time: string;
    case_id: null;
    refund: string | null;
  }
  | {
    event: Exclude<EventValues, 'CASE_CREATED' | 'REFUND_UPDATED'>;
    time: string;
    case_id: null;
  };

export type RefundStatus = 'Full Refund' | 'Partial Refund' | 'Exchange' | 'Not Refund' | 'Others';

export type Refund = {
  status: RefundStatus;
  info: {};
  amount: number;
  currency: string;
  last_request_time: Date;
  request_count: number;
  partial_refund: Array<number>;
};

export enum HistoryEvents {
  TRANSACTION_CREATED = 'TRANSACTION_CREATED',
  INITIAL_RECEIPT_SENT = 'INITIAL_RECEIPT_SENT',

  TRACK_INFO_ENQUIRY = 'TRACK_INFO_ENQUIRY',
  TRACK_INFO_REQUESTED = 'TRACK_INFO_REQUESTED',
  TRACK_INFO_UPDATED = 'TRACK_INFO_UPDATED',
  TRACK_RECEIPT_SENT = 'TRACK_RECEIPT_SENT',

  DELIVERY_SIGNED_UPDATED = 'DELIVERY_SIGNED_UPDATED',

  REPORT_ISSUE = 'REPORT_ISSUE',
  CASE_CREATED = 'CASE_CREATED',

  REFUND_UPDATED = 'REFUND_UPDATED',


  ARN_UPDATED = 'ARN_UPDATED',

  OI_CASE_CREATED = 'OI_CASE_CREATED',
  CE_CASE_CREATED = 'CE_CASE_CREATED',
  CE_CASE_STATUS_UPDATED = 'CE_CASE_STATUS_UPDATED',

  CLOSED = 'CLOSED',
};

export const mapHistoryMessage = (data) => {
  switch (data) {
    case HistoryEvents.TRANSACTION_CREATED:
      return 'Order Confirmed';
    case HistoryEvents.INITIAL_RECEIPT_SENT:
      return 'Order Confirmation Email Sent';
    case HistoryEvents.TRACK_INFO_ENQUIRY:
      return 'Buyer Enquired Delivery Status';
    case HistoryEvents.TRACK_INFO_REQUESTED:
      return 'Buyer Requested Delivery Status Update';
    case HistoryEvents.TRACK_INFO_UPDATED:
      return 'Merchant Updated The Delivery Status';
    case HistoryEvents.TRACK_RECEIPT_SENT:
      return 'Shipping Confirmation Email Sent';
    case HistoryEvents.REPORT_ISSUE:
      return 'Buyer Enquired Order Status';
    case HistoryEvents.CASE_CREATED:
      return 'Buyer Service Requested';
    case HistoryEvents.REFUND_UPDATED:
      return 'Merchant Updated Refund Information';
    case HistoryEvents.DELIVERY_SIGNED_UPDATED:
      return 'Merchant Updated Delivery Signed';
    case HistoryEvents.ARN_UPDATED:
      return 'ARN Update';
    case HistoryEvents.OI_CASE_CREATED:
      return 'Receive OI Request';
    case HistoryEvents.CE_CASE_CREATED:
      return 'Receive CE Request';
    case HistoryEvents.CE_CASE_STATUS_UPDATED:
      return 'CE Case Status Update';
    case HistoryEvents.CLOSED:
      return 'Case Closed';

    default:
      return data;
  }
}

export enum IssueReason {
  OTHER = 'other',
  TRANSACTION_FRAUD = 'transaction/fraud',
  TRANSACTION_WRONG_AMOUNT = 'transaction/wrong-amount',
  TRANSACTION_MULTIPLE = 'transaction/multiple',
  TRANSACTION_MISTAKE = 'transaction/mistake',
  TRANSACTION_CANCEL = 'transaction/cancel',
  SHIPPING_CANNOT_LOCATE = 'shipping/cannot-locate',
  SHIPPING_TOO_LONG = 'shipping/too-long',
  ITEM_NOT_MATCH = 'items/not-match',
  ITEM_DEFECTIVE = 'items/defective',
  ITEM_COUNTERFEIT = 'items/counterfeit',
  ITEM_NOT_RECEIVE = 'items/not-receive',
};

export const mapIssueMessage = (data) => {
  switch (data) {
    case IssueReason.ITEM_NOT_RECEIVE:
      return 'Items Not Received As Described';
    case IssueReason.ITEM_COUNTERFEIT:
      return 'Counterfeit Goods';
    case IssueReason.ITEM_NOT_MATCH:
      return 'Product Does Not Match The Description';
    case IssueReason.ITEM_DEFECTIVE:
      return 'Parcel/Product Was Damaged';
    case IssueReason.SHIPPING_CANNOT_LOCATE:
      return 'Cannot Locate The Package';
    case IssueReason.SHIPPING_TOO_LONG:
      return 'Poor Delivery Service';
    case IssueReason.TRANSACTION_CANCEL:
      return 'Cancel Transaction';
    case IssueReason.TRANSACTION_MISTAKE:
      return 'Order Misplaced by a Family Member';
    case IssueReason.TRANSACTION_MULTIPLE:
      return 'Multiple Orders Submitted';
    case IssueReason.TRANSACTION_FRAUD:
      return 'Fraudulent Transaction';
    case IssueReason.TRANSACTION_WRONG_AMOUNT:
      return 'Wrong Amount';
    case IssueReason.OTHER:
      return 'Other issue';

    default:
      return data;
  }
}

export const mapPartialRefund = (data) => {
  switch (data) {
    case 'Partial Refund':
      return 'Refunded';
    default:
      return data;
  }
}