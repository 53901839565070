type Auth = {
  token?: string | null;
  refresh?: () => Promise<string | null> | null;
};

type BaseURLTypes = 'ethoca' | 'ics' | 'oi' | 'ics-message' | 'rdr' | 'cbp-service' | 'statistics';

export class HTTPError extends Error {
  public constructor(public response: Response) {
    super();
  }
}
export class Unauthenticated extends Error { }

export async function api(
  path: string,
  options: RequestInit,
  { token, refresh }: Auth,
  serviceUrlType: BaseURLTypes = 'ethoca',
  type: 'INTERNAL' | 'MERCHANT' = 'INTERNAL'
): Promise<Response | null> {
  try {
    let serviceUrl: string;
    switch (serviceUrlType) {
      case 'ics':
        serviceUrl = 'ics/';
        break;
      case 'oi':
        serviceUrl = 'oi/';
        break;
      case 'ics-message':
        serviceUrl = 'ics-message/';
        break
      case 'rdr':
        serviceUrl = 'rdr/';
        break;
      case 'cbp-service':
        break;
      case 'statistics':
        serviceUrl = 'statistics/';
        break;
      case 'ethoca':
      default:
        serviceUrl = 'ethoca/';
        break;
    }

    if (!token && type === 'INTERNAL') {
      const newToken = await refresh();
      if (newToken) {
        return await api(path, options, {
          token: newToken,
          refresh: () => null,
        }, serviceUrlType);
      } else {
        throw new Unauthenticated('Unable to authenticate');
      }
    }

    const response = await fetch(new URL(path, process.env.REACT_APP_CBP_SERVICE_BASE_URL + serviceUrl).href, {
      ...options,
      headers: {
        authorization: `Bearer ${token}`,
        ...options?.headers,
      },
    });

    // TODO: Update handle for token expired and if refresh failed logout
    if (response.status === 401) {
      const newToken = await refresh();
      if (newToken) {
        return await api(path, options, {
          token: newToken,
          refresh: () => null,
        }, serviceUrlType);
      }
    }

    // TODO: Add handle for unauth

    if (!response.ok) {
      throw new HTTPError(response);
    }

    return response;
  } catch (err) {
    if (err instanceof DOMException && err.name === 'AbortError') {
      return null;
    }
    throw err;
  }
}