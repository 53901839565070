import clsx from 'clsx';
import { endOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useParams } from 'react-router-dom';
import { useFilter } from '../../FilterContext';
import SearchInputGroup from '../../components/SearchInputGroup';
import SingleSelection from '../../components/SingleSelection';
import DatePickerFilter from '../components/DatePickerFilter';
import MultiSelection from '../../components/MultiSelection';
import { SelectType } from '../../user/usersFilter/UsersFilter';
import { useIntl } from 'react-intl';

export function OiCaidsFilter() {
  const params = useParams();
  const intl = useIntl()
  const { globalFilter, setGlobalFilter } = useFilter();

  const status: SelectType[] = [
    { value: 'PENDING', label: intl.formatMessage({ id: 'DESCRIPTORS.STATUS_OPTIONS.PENDING' }) },
    { value: 'PROCESSING', label: intl.formatMessage({ id: 'DESCRIPTORS.STATUS_OPTIONS.PROCESSING' }) },
    { value: 'ADD_PENDING_PROCESSING', label: intl.formatMessage({ id: 'DESCRIPTORS.STATUS_OPTIONS.ADD_PENDING_PROCESSING' }) },
    { value: 'ACTIVE', label: intl.formatMessage({ id: 'DESCRIPTORS.STATUS_OPTIONS.ACTIVE' }) },
    { value: 'DELETE_PENDING', label: intl.formatMessage({ id: 'DESCRIPTORS.STATUS_OPTIONS.DELETE_PENDING' }) },
    { value: 'DELETE_PROCESSING', label: intl.formatMessage({ id: 'DESCRIPTORS.STATUS_OPTIONS.DELETE_PROCESSING' }) },
    { value: 'DELETED', label: intl.formatMessage({ id: 'DESCRIPTORS.STATUS_OPTIONS.DELETED' }) },
    { value: 'REJECTED', label: intl.formatMessage({ id: 'DESCRIPTORS.STATUS_OPTIONS.REJECTED' }) },
  ];

  return (
    <>
      <div className={clsx(params.id ? 'blur' : '')}>
        {/* Search */}
        <SearchInputGroup
          inputs={[
            { key: 'merchant_id', title: 'Client ID' },
            { key: 'dba', title: 'DBA' },
            { key: 'bin', title: 'BIN' },
            { key: 'caid', title: 'CAID' },
          ]}
          section='oiCaids'
          globalFilterValue={globalFilter}
          onSubmit={(searchValues) =>
            setGlobalFilter({
              ...globalFilter,
              oiCaids: { ...globalFilter.oiCaids, ...searchValues },
            })
          }
          onClear={(searchValues) =>
            setGlobalFilter({
              ...globalFilter,
              oiCaids: { ...globalFilter.oiCaids, ...searchValues },
            })
          }
        />

        <div className='hstack justify-content-between align-items-end'>
          {/* Select */}
          <div className='d-flex'>
            <MultiSelection
              title='Status'
              globalFilterValue={globalFilter?.['oiCaids']?.status?.values}
              options={status}
              hideOptions={
                status.filter((status) => status.value === 'PROCESSING' || status.value === 'ADD_PENDING_PROCESSING' || status.value === 'DELETE_PROCESSING')
              }
              onChange={(e) => {
                const values = []
                e.forEach((option) => {
                  values.push(option)
                  if (option.value === 'PENDING') {
                    values.push(status.find((e) => e.value === 'PROCESSING'))
                    values.push(status.find((e) => e.value === 'ADD_PENDING_PROCESSING'))
                  } else if (option.value === 'DELETE_PENDING') {
                    values.push(status.find((e) => e.value === 'DELETE_PROCESSING'))
                  }
                })
                setGlobalFilter({ ...globalFilter, oiCaids: { ...globalFilter.oiCaids, status: { type: "in", values: values } } });
              }} />
          </div>
        </div>
      </div>
    </>
  );
}
