export const refundStatus = {
    FULL_REFUND: 'Full Refund',
    PARTIAL_REFUND: 'Partial Refund',
    EXCHANGE: 'Exchange',
    NOT_REFUND: 'Not Refund',
    OTHERS: 'Others',
}

export const oiStatus = {
    COMPLETED: 'Completed',
    PROCESSING: 'Processing',
    FAILED: 'Failed',
    ERROR: 'Error'
  }