import clsx from 'clsx';
import { endOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useParams } from 'react-router-dom';
import { useFilter } from '../../FilterContext';
import SearchInputGroup from '../../components/SearchInputGroup';
import SingleSelection from '../../components/SingleSelection';
import DatePickerFilter from '../components/DatePickerFilter';

export function LookupsFilter() {
  const params = useParams();
  const { globalFilter, setGlobalFilter } = useFilter();

  return (
    <>
      <div className={clsx(params.id ? 'blur' : '')}>
        {/* Search */}
        <SearchInputGroup
          inputs={[
            { key: 'insight_id', title: 'Insight ID', minWidth: 300 },
            // { key: 'transaction.amount', title: 'Txn Amt', minWidth: 220 },
            // { key: 'card_info.card_prefix', title: 'Card Prefix', minWidth: 200 },
            // { key: 'card_info.card_suffix', title: 'Card Suffix', minWidth: 200 },
            // { key: 'transaction.auth_code', title: 'Auth Code', minWidth: 200 },
            { key: 'transaction.arn', title: 'ARN', minWidth: 200 },
            { key: 'transaction.descriptor', title: 'Descriptor', minWidth: 200 },
            { key: 'transaction.acquirer_bin', title: 'BIN', minWidth: 200 },
            { key: 'transaction.card_acceptor_id', title: 'CAID', minWidth: 200 },
            { key: 'merchant_id.ics', title: 'Client ID', minWidth: 200 },
          ]}
          section='oiLookups'
          globalFilterValue={globalFilter}
          onSubmit={(searchValues) =>
            setGlobalFilter({
              ...globalFilter,
              oiLookups: { ...globalFilter.oiLookups, ...searchValues },
            })
          }
          onClear={(searchValues) =>
            setGlobalFilter({
              ...globalFilter,
              oiLookups: { ...globalFilter.oiLookups, ...searchValues },
            })
          }
        />

        <div className='hstack justify-content-between align-items-end'>
          {/* Select */}
          <div className='d-flex'>
            {/* Date Picker */}
            <DatePickerFilter
              onChange={(selectedDate) =>
                setGlobalFilter({
                  ...globalFilter,
                  oiLookups: {
                    ...globalFilter.oiLookups,
                    'transaction.date': selectedDate && {
                      from: zonedTimeToUtc(new Date(selectedDate.start), 'Hongkong').toISOString(),
                      to: zonedTimeToUtc(
                        new Date(endOfDay(selectedDate.end)),
                        'Hongkong'
                      ).toISOString(),
                    },
                  },
                })
              }
              label='Txn Date'
              placeholder='Filter Txn Date...'
              className='me-6'
            />

            <SingleSelection
              title='Type'
              globalFilterValue={globalFilter?.oiLookups?.['source']?.values}
              options={[
                { value: 'OI', label: 'OI' },
                { value: 'OID', label: 'OID' },
                { value: 'CE', label: 'CE' },
              ]}
              isClearable
              onChange={(e) => {
                setGlobalFilter({
                  ...globalFilter,
                  oiLookups: {
                    ...globalFilter.oiLookups,
                    'source': e?.value ? { type: 'in', values: [e] } : undefined,
                  },
                });
              }}
            />

            <SingleSelection
              title='Response Status'
              globalFilterValue={globalFilter?.oiLookups?.['response_status']?.values}
              options={[
                { value: 'COMPLETED', label: 'Completed' },
                { value: 'NOT_FOUND', label: 'Not Found' },
                { value: 'MISSING_REQUIRED_FIELD', label: 'Missing Fields' },
              ]}
              isClearable
              onChange={(e) => {
                setGlobalFilter({
                  ...globalFilter,
                  oiLookups: {
                    ...globalFilter.oiLookups,
                    'response_status': e?.value ? { type: 'in', values: [e] } : undefined,
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
