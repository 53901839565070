import { creditCardType } from "../../../../../../../_metronic/helpers/AssetHelpers";

export function receiptTemplate(data, merchant, type: "SHIPPED" | "RECEIPT") {
    const imgPath = process.env.REACT_APP_ICS_BUYER_IMG_BASE_URL
    const ICONS = {
        thanks: `${imgPath}icons/shoppingCart.png`,
        thanksDark: `${imgPath}icons/shoppingCartDark.png`,
        ship: `${imgPath}icons/truck.png`,
        shipDark: `${imgPath}icons/truckDark.png`,
        bot: `${imgPath}icons/botGray.png`,
        botDark: `${imgPath}icons/botDark.png`,
        click: `${imgPath}icons/click.png`,
        clickDark: `${imgPath}icons/clickDark.png`,
        logo: `${imgPath}logo-gray.png`,
        logoDark: `${imgPath}logo-dark.png`,
    };
    const FONT_FAMILY =
        'font-family: "Open Sans", "Helvetica Neue", Helvetica, system-ui;';
    const TXN_DETAILS_TD_STYLE =
        'border-bottom-width: 1px; border-bottom-color: #ddd; border-bottom-style: solid; font-size: 15px; line-height: 24px; margin: 0; padding: 10px;';
    const ORDER_LIST_TD_STYLE =
        'font-size: 15px; line-height: 24px; margin: 0;';
    const ORDER_LIST_TD_WITH_BOTTOM_BORDER_STYLE = `${ORDER_LIST_TD_STYLE} border-bottom-width: 1px; border-bottom-color: #ddd; border-bottom-style: solid; padding-bottom: 10px;`;
    const BORDER_STYLE =
        'border-width: 0.5px; border-style: solid; border-color: #e1a6b0;';
    const SHIPPING_ADDRESS_STYLE = 'font-size: 15px; line-height: 24px; margin: 0; padding-left: 10px; padding-right: 10px;'

    const EM_DASH = '—';

    let shipmentLandingPageUrl = '';
    let cardPNG = '';
    let emailTitle = '';
    let titlePNG = '';
    let titlePNGDark = '';
    let status = '';

    // HTML variables
    let shipButton = '';
    let shipAddressRow = '';
    let merchantThanksMessage = '';
    let orderList = '';

    if (type === 'RECEIPT') {
        emailTitle = 'Thank You';
        titlePNG = ICONS.thanks;
        titlePNGDark = ICONS.thanksDark;
        status = `Order Date ${data.transaction.transaction_date_time}`;
        shipmentLandingPageUrl = '#';
    } else {
        emailTitle = 'Your Order Was Shipped';
        titlePNG = ICONS.ship;
        titlePNGDark = ICONS.shipDark;
        status = `Shipped ${data.product_info.shipping_date}`;
        shipmentLandingPageUrl = `${process.env.SHIPMENT_LANDING_PAGE_URL}${data.iCS_id}`;
        shipButton = `
        <td style='line-height: 24px; text-align: center; margin: 0; width: 50%;'>
            <div
                style='background-color: #000000; color: #fff; display: block; text-decoration: none; border-radius: 25px; font-size: 14px; text-align: center; margin: 0 12px; padding: 12px 20px; margin-right: 12px; margin-left: 10px; ${BORDER_STYLE}'>
                Track Package
            </div>
        </td>
    `;
    }

    let cc = creditCardType(
        `${data.transaction.card_prefix}000000${data.transaction.card_suffix}`
    );
    if (cc === 'VISA') {
        cardPNG = `${imgPath}card/visa.png`;
    } else if (cc === 'MASTERCARD') {
        cardPNG = `${imgPath}card/mastercard.png`;
    } else {
        cc = '';
    }

    if (data.product_info?.shipping_address || data.product_info?.shipping_address_1) {
        const shipping_address = data.product_info?.shipping_address ?? "";
        const shipping_address_1 = data.product_info?.shipping_address_1 ?? "";
        const combinedAddress = shipping_address.concat(",", shipping_address_1)
        const addressArray = combinedAddress.split(',').filter((address) => address !== '');
        let address = '';
        for (const addressSplit of addressArray) {
            address = `${address}${addressSplit}<br>`;
        }

        if (data.product_info?.city || data.product_info?.region || data.product_info?.postalcode || data.product_info?.country) {
            shipAddressRow = `
            <tr>
                <td style='${SHIPPING_ADDRESS_STYLE} font-weight: bold; padding-top: 10px;'>
                    Shipping To:
                </td>
            </tr>
            <tr>
                <td style='${SHIPPING_ADDRESS_STYLE}'>
                    Address
                </td>
                <td style='${SHIPPING_ADDRESS_STYLE}'>
                    ${address}
                </td>
            </tr>
            ${data.product_info?.city && `<tr>
                <td style='${SHIPPING_ADDRESS_STYLE}'>
                    City
                </td>
                <td style='${SHIPPING_ADDRESS_STYLE}'>
                    ${data.product_info?.city}
                </td>
            </tr>`}
            ${data.product_info?.region && `<tr>
                <td style='${SHIPPING_ADDRESS_STYLE}'>
                    Region
                </td>
                <td style='${SHIPPING_ADDRESS_STYLE}'>
                    ${data.product_info?.region}
                </td>
            </tr>`}
             ${data.product_info?.postalcode && `<tr>
                <td style='${SHIPPING_ADDRESS_STYLE}'>
                    Postal Code
                </td>
                <td style='${SHIPPING_ADDRESS_STYLE}'>
                    ${data.product_info?.postalcode}
                </td>
            </tr>`}
            ${data.product_info?.country && `<tr>
                <td style='${SHIPPING_ADDRESS_STYLE}'>
                    Country
                </td>
                <td style='${SHIPPING_ADDRESS_STYLE}'>
                    ${data.product_info?.country}
                </td>
            </tr>`}
            <tr>
                <td style='padding-bottom: 10px; border-bottom-width: 1px; border-bottom-color: #ddd; border-bottom-style: solid;'>
                </td>
                <td style='padding-bottom: 10px; border-bottom-width: 1px; border-bottom-color: #ddd; border-bottom-style: solid;'>
                </td>
            </tr>
            `;
        } else {
            shipAddressRow = `
            <tr>
                <td style='${TXN_DETAILS_TD_STYLE} font-weight: bold;'>
                    Shipping To:
                </td>
                <td style='${TXN_DETAILS_TD_STYLE}'>
                    ${address}
                </td>
            </tr>
        `;
        }
    }

    // Purchase URL
    if (merchant.purchase_url && data.product_info?.purchase_url) {
        const merchantUrl = data.product_info.purchase_url.startsWith('http')
            ? data.product_info.purchase_url
            : `https://${data.product_info.purchase_url}`;

        merchantThanksMessage = `
        <div style='font-size: 15px; ${FONT_FAMILY}; line-height: 24px; font-style: italic; text-align: center; margin: 0; padding: 15px 20px 0; '>
            Thank you for shopping at
            <a href='${merchantUrl}' target='_blank'
                style='color: black; font-weight: bold;'
            >${new URL(merchantUrl).hostname.replace('www.', '')}</a>
        </div>
    `;
    }

    // Order List
    if (merchant.order_list && data.product_info?.list?.length) {
        const items = data.product_info.list
            .map((item, index, array) => {
                let cellStyles = '';
                // if (index === array.length - 1)  // border bottom for Total row
                //     cellStyles = `${ORDER_LIST_TD_WITH_BOTTOM_BORDER_STYLE} vertical-align: top; padding-top: 2px;`;
                // else {
                cellStyles = `${ORDER_LIST_TD_STYLE} vertical-align: top; padding-bottom: 2px; ${index === 0 ? 'padding-top: 10px;' : 'padding-top: 2px;'
                    }`;
                // }
                const productCurrency = item?.currency || ''

                return `
                <tr>
                    <td style='${cellStyles} width: 70%; '>
                        ${item?.name || EM_DASH}
                    </td>
                    <td style='${cellStyles} text-align: center; padding-left: 5px; padding-right: 5px;'>
                        ${item?.qty || EM_DASH}
                    </td>
                    <td style='${cellStyles} text-align: right;'>
                        ${item?.amount
                        ? productCurrency + '&nbsp;' + Number(item?.amount).toFixed(2)
                        : EM_DASH
                    }
                    </td>
                </tr>
            `;
            })
            .join('');

        orderList = `
        <div style='margin: 30px; margin-top: 10px; padding: 15px; border-radius: 5px; background-color: #FCF6F6; ${FONT_FAMILY}'>  
            <table style='width: 100%; border-collapse: collapse;' border='0' cellpadding='0' cellspacing='0'>
                <tr>
                    <td style='${ORDER_LIST_TD_WITH_BOTTOM_BORDER_STYLE} width: 70%; font-weight: bold;'>
                        Order List
                    </td>
                    <td style='${ORDER_LIST_TD_WITH_BOTTOM_BORDER_STYLE} padding-left: 5px; padding-right: 5px; text-align: center;'>
                        Qty
                    </td>
                    <td style='${ORDER_LIST_TD_WITH_BOTTOM_BORDER_STYLE} text-align: right;'>
                        Price
                    </td>
                </tr>
                ${items}
                ${''
            // `<tr>
            //     <td style='${ORDER_LIST_TD_STYLE} font-weight: bold; padding-top: 10px;' colspan=2>
            //         Total
            //     </td>
            //     <td style='${ORDER_LIST_TD_STYLE} font-weight: bold; padding-top: 10px; text-align: right;'>
            //         ${data.transaction.currency}&nbsp;${Number(data.transaction.amount).toFixed(2)}
            //     </td>
            // </tr>`
            }
            </table>
        </div>
    `;
    }

    return `
<!DOCTYPE html>
<html lang='en' xmlns:v='urn:schemas-microsoft-com:vml'>

<head>
    <meta charset='UTF-8'>
    <meta name='x-apple-disable-message-reformatting'>
    <meta name='viewport' content='width=device-width, initial-scale=1'>
    <meta name='format-detection' content='telephone=no, date=no, address=no, email=no, url=no'>
    <meta name='color-scheme' content='light dark'>
    <meta name='supported-color-schemes' content='light dark'>
    <!--[if mso]>
        <noscript>
            <xml>
                <o:OfficeDocumentSettings xmlns:o='urn:schemas-microsoft-com:office:office'>
                    <o:PixelsPerInch>96</o:PixelsPerInch>
                </o:OfficeDocumentSettings>
            </xml>
        </noscript>
        <style>
            td, th, div, p, a, h1, h2, h3, h4, h5, h6 {
                ${FONT_FAMILY}
                mso-line-height-rule: exactly;
            }
        </style>
    <![endif]-->
    <style>
        @media (prefers-color-scheme: dark) {
            .ogsb-inline {
                display: inline !important
            }
            .ogsb-hidden {
                display: none !important
            }
        }
            
        [data-ogsb] .ogsb-inline {
            display: inline !important
        }
    
        [data-ogsb] .ogsb-hidden {
            display: none !important
        }
    </style>

    <style>
        a[x-apple-data-detectors] {
            color: inherit !important;
            text-decoration: none !important;
            font-size: inherit !important;
            font-family: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
        }
    </style>
</head>

<body>
    <table class='body' valign='top' role='presentation' border='0' cellpadding='0' cellspacing='0'
        style='outline: 0; width: 98%; min-width: 98%; height: 100%; margin: 0; padding: 0; border-width: 0;'>
        <tbody>
            <tr>
                <td valign='top' style='line-height: 24px; font-size: 16px; margin: 0;' align='left'>
                    <div style='background-color: #f0e5de; padding: 20px;'>
                        <div
                            style='width: 100%; max-width: 550px; background-color: #ffffff; border-radius: 15px; overflow: hidden; margin: 0 auto; ${BORDER_STYLE}'>
                            <div style='background-color: #eb9f9f; border-radius: 15px 15px 0 0; padding: 40px 0px 30px;'>
                                <div
                                    style='font-family: "Times New Roman"; font-style: italic; font-size: 32px; font-weight: 600; text-align: center; margin-bottom: 20px;'>
                                    ${emailTitle}
                                </div>
                                <div align='center'>
                                    <img class='ogsb-hidden'
                                        src='${titlePNG}'
                                        alt='${emailTitle}'
                                        style='height: 65px; width: auto; line-height: 100%; outline: none; text-decoration: none; border-style: none; border-width: 0;'>
                                    <img class='ogsb-inline'
                                        src='${titlePNGDark}'
                                        alt='${emailTitle} dark'
                                        style='height: 65px; width: auto; line-height: 100%; outline: none; text-decoration: none; border-style: none; border-width: 0; display: none;'>
                                </div>
                                ${merchantThanksMessage}
                            </div>

                            <div style='background-color: #f0e5de; padding: 15px 30px;'>
                                <table style='width: 100%; border-collapse: collapse; ${FONT_FAMILY}' border='0' cellpadding='0' cellspacing='0'>
                                    <tr>
                                        <td style='padding-right: 15px; height: 50px; width: 55px;'>
                                            <img class='ogsb-hidden'
                                                src='${ICONS.bot}'
                                                alt='bot'
                                                style='vertical-align: middle; height: 50px; width: auto; outline: none; text-decoration: none; border-style: none; border-width: 0;'>
                                            <img class='ogsb-inline'
                                                src='${ICONS.botDark}'
                                                alt='bot dark'
                                                style='vertical-align: middle; height: 50px; width: auto; outline: none; text-decoration: none; border-style: none; border-width: 0; display: none;'>
                                        </td>
                                        <td style='line-height: 24px;'>
                                            <p style='color: #828282; font-size: 12px; line-height: 16px; text-align: center; margin: 0;'>
                                                You received this email because of your recent purchase. 
                                                <span style='font-weight: bold; color: #000;'>
                                                    Beceipt is an automated customer service robot subscribed by the seller
                                                </span>
                                                to assist you with your online purchase.
                                            </p>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                            <div style='padding: 30px; ${FONT_FAMILY}'>
                                <table style='width: 100%; border-collapse: collapse;' border='0' cellpadding='0' cellspacing='0'>
                                    <tr>
                                        <td style='${TXN_DETAILS_TD_STYLE} font-weight: bold;'>
                                            Status:
                                        </td>
                                        <td style='${TXN_DETAILS_TD_STYLE}'>
                                            ${status}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style='${TXN_DETAILS_TD_STYLE} font-weight: bold;'>
                                            Amount:
                                        </td>
                                        <td style='${TXN_DETAILS_TD_STYLE}'>
                                            ${data.transaction?.amount
            ? data.transaction.currency + ' ' + Number(data.transaction.amount).toFixed(2)
            : EM_DASH
        }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style='${TXN_DETAILS_TD_STYLE} font-weight: bold;'>
                                            Merchant&nbsp;Name&nbsp;On Statement:
                                        </td>
                                        <td style='${TXN_DETAILS_TD_STYLE}'>
                                            ${data.transaction?.merchant_name || EM_DASH}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style='${TXN_DETAILS_TD_STYLE} font-weight: bold;'>
                                            Order ID:
                                        </td>
                                        <td style='${TXN_DETAILS_TD_STYLE}'>
                                            ${data.transaction.order_reference}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style='${TXN_DETAILS_TD_STYLE} font-weight: bold;'>
                                            Paid By:
                                        </td>
                                        <td style='${TXN_DETAILS_TD_STYLE}'>
                                            ${data.transaction?.card_suffix
            ? `<p style='line-height: 24px; width: 100%; margin: 0; padding: 0px;'>
                                                        <span>
                                                            **** **** **** ${data.transaction.card_suffix}
                                                        </span>
                                                        <img
                                                            style='vertical-align: middle; height: auto; line-height: 100%; outline: none; text-decoration: none; border-style: none; border-width: 0;'
                                                            src='${cardPNG}' alt='${cc.toLowerCase()}' width='26'>
                                                    </p>`
            : EM_DASH
        }
                                        </td>
                                    </tr>
                                    ${shipAddressRow}
                                </table>
                            </div>

                            ${orderList}

                            <div style='padding: 0 10px 15px; ${FONT_FAMILY}'>
                                <table style='width: 100%; border-collapse: collapse;' border='0' cellpadding='0' cellspacing='0'>
                                    <tr>
                                        <td ${shipButton && 'colspan=2'}
                                            style='line-height: 24px; margin: 0; padding-bottom: 15px;'>
                                            <div align='center'>
                                                <span style='font-size: 15px; color: #000;'>
                                                    Question Or Issue About Your Order?
                                                </span>
                                                <img src='${ICONS.click}'
                                                    class='ogsb-hidden' alt='click'    
                                                    style='max-width: 100%; vertical-align: middle; line-height: 1; height: 25px; width: auto; outline: none; text-decoration: none; border-style: none; border-width: 0;'>
                                                <img src='${ICONS.clickDark}'
                                                    class='ogsb-inline' alt='click dark'
                                                    style='max-width: 100%; vertical-align: middle; line-height: 1; height: 25px; width: auto; outline: none; text-decoration: none; border-style: none; border-width: 0; display: none;'>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style='line-height: 24px; margin: 0; text-align: center; ${shipButton && 'width: 50%;'}'>
                                            <div target='_blank'
                                                style='background-color: #000000; color: #fff; display: block; text-decoration: none; border-radius: 25px; font-size: 14px; text-align: center; padding: 12px 20px; margin-left: 12px; margin-right: 10px; ${BORDER_STYLE}'>
                                                Report Issue
                                            </div>
                                        </td>
                                        ${shipButton}
                                    </tr>
                                </table>

                                <table style='width: 100%; border-collapse: collapse;' border='0' cellpadding='0' cellspacing='0'>
                                    <tr>
                                        <td style='padding-top: 20px; line-height: 24px; text-align: center; margin: 0;'>
                                            <p style='line-height: 24px; width: 100%; margin: 0; text-align: center;'>
                                                <span style='font-size: 15px; color: #777;'>
                                                    Powered by
                                                </span>
                                                <img src='${ICONS.logo}' 
                                                    class='ogsb-hidden' alt='logo'
                                                    style='max-width: 100%; vertical-align: middle; line-height: 1; border: 0; height: 24px; width: auto;'>
                                                <img src='${ICONS.logoDark}' 
                                                    class='ogsb-inline' alt='logo dark'
                                                    style='max-width: 100%; vertical-align: middle; line-height: 1; border: 0; display: none; height: 24px; width: auto;'>
                                            </p>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</body>

</html>
`;
}
